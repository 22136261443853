import React,{ useContext, useEffect,useState } from 'react'
import { useLocation,useNavigate } from "react-router-dom"
import { AppContext } from '../contexts/AppProvider'
import defaultExport from 'module'
import axios from 'axios'
import SuccessAlert from './SuccessAlert'
import ErrorAlert from './ErrorAlert'

const CreateWorkspace = () => {

    const {setWorkspaces} = useContext(AppContext)
    const vreli_domain = process.env.REACT_APP_VRELI_DOMAIN; 
    const [companyWorkspace,setcompanyWorkspace] = useState("");
    const [Flash,setFlash] = useState("");
    const [Message,setMessage] = useState("");

    const navigate = useNavigate();
    const location = useLocation()
    const params   = new URLSearchParams(location.search)
    let url_email  = params.get("email")
    let url_otp    = params.get("otp")
    const react_url      = process.env.REACT_APP_URL; 
    let find_workspace_url = `${react_url}/find-workspace?email=${url_email}&otp=${url_otp}`;

    const inputcompanyWorkspace = (event) => {
        setcompanyWorkspace(event.target.value);
    };

    const submitWorkspace = (event) => {
		event.preventDefault();
        //Post api
        const data = {
            email       : url_email,
            otp         : url_otp,
            workspace   : companyWorkspace,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL; 
        const url = `${env_url}/apis/create-workspace`;
        axios.post(url,data)
        .then(res => {
            if(res.data['error']==0)
            {
                setFlash("success");
                setMessage(res.data['error_message']);
            }
            else
            {
                setMessage(res.data['error_message']);
                setFlash("error");
            }
        })
    }

    const findWorkspace = () => {
        //Post api
        const data = {
            email       : url_email,
            otp         : url_otp,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL; 
        const url = `${env_url}/apis/find-workspace`;
        axios.post(url,data)
        .then(res => {
            // debugger
            setWorkspaces(res.data.workspaces_data)

            //redirect
            navigate(`/find-workspace?email=${url_email}&otp=${url_otp}`);
        })
    }

    return (
        <section className="block block--split block--flex block--create-workspace block--mobile-reverse block--overflow-hidden">
            <div className="col-md-4">
                <div className="workspace-wrap workspace-wrap--input">
                    <div className="workspace__title-wrap">
                        <h2 className="workspace__title">Create new company workspace</h2>
                    </div>
                    <div className="workspace__content-wrap">
                        <div className="workspace__content">Enter your workspace’s <b>{vreli_domain}</b> URL.</div>
                        <form action="confirm_otp.html" className="form form--workspace block__content--center create-workspace-form" onSubmit={submitWorkspace}>
                            <div className="form__input-group">
                                <input type="text" className="form__control form__control--whitebg js-input-email company-domain" name="workspace_alias" placeholder="" onChange={inputcompanyWorkspace} value={companyWorkspace} />
                                <span className="form__control-text">.{vreli_domain}</span>
                            </div>
                            {/* <!-- Alert Flash Message --> */}
                                <div className="flash-message">
                                    {Flash=="success"?<SuccessAlert data={Message} /> : null}
                                    {Flash=="error"?<ErrorAlert data={Message} /> : null}
                                </div>
                            {/* <!-- End Alert Flash Message --> */}
                            <input type="submit" className="btn form__input-submit" value="Submit" />
                        </form>
                    </div>
                    <div className="workspace__bottom-wrap">

                        <p className="workspace__bottom">  Want to find your workspaces? Please <a href="#" onClick={findWorkspace} className="text-primary">click here</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateWorkspace